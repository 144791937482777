<template>
  <div>
    <el-col :span="24">
      <div class="box-table-detail">
        <p class="font-24 text-center mg-b-6">เกณฑ์ในการพิจารณาโครงการ</p>
        <TableDetailProject :data="dataTable[0]" page="project" />
      </div>
    </el-col>
    <el-col :span="24">
      <div class="card grade-size">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-row :gutter="20" class="custom-flex">
              <el-col :span="24" :sm="8" :md="6" class="mg-t-6 text-center">
                <img
                  v-if="data.image != ''"
                  :src="imgUrlTERAK + data.image"
                  alt=""
                  @click="$emit('morePicture', imgUrlTERAK + data.image)"
                  class="imgCredit cursor-pointer"
                />
                <img
                  v-else
                  :src="require(`@/assets/image-default.jpg`)"
                  alt=""
                  class="imgCredit"
                />
              </el-col>
              <el-col :span="24" :sm="16" :md="18" class="mg-t-6 text-left">
                <div class="tab-card-sm detail-project">
                  <div class="inside">
                    <p class="title">{{ data.projectName }}</p>
                    <p class="font-24 color-00">รายละเอียดโครงการ</p>
                    <div class="output ql-snow">
                      <div
                        class="ql-editor"
                        v-html="data.projectDescription"
                      ></div>
                    </div>
                    <el-row>
                      <el-col :span="24">
                        <div class="time-out-detail is-flex js-between">
                          <span>
                            <i class="fas fa-calendar-alt"></i>
                            <span class="font-weight-bold">วันที่เปิดรับสมัคร
                            </span>
                            {{ data.registerStart | formatDate }} -
                            {{ data.registerEnd | formatDate }}
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <div class="time-out-detail mg-t-0">
                          <i class="fas fa-calendar-alt"></i>
                          <span class="font-weight-bold"
                            >วันที่จัดกิจกรรม
                          </span>
                          {{ data.eventDate | formatDate }} -
                          {{ data.eventDateEnd | formatDate }}
                        </div>
                      </el-col>
                      <el-col :span="24" v-if="data.registerType == 2">
                        <div class="time-out-detail mg-t-0">
                          <i class="fas fa-link link"></i>
                          <span class="font-weight-bold">
                            ลิงก์สมัครโครงการ:
                          </span>
                          <a
                            :href="returnUrl(data.registerUrl)"
                            target="_blank"
                            style="color: #034ea2"
                          >
                            {{ returnUrl(data.registerUrl) }}
                          </a>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <div class="time-out-detail mg-t-0">
                          <i class="fas fa-map-marker-alt map"></i>
                          <span class="font-weight-bold"
                            >สถานที่จัดกิจกรรม
                          </span>
                          {{ data.eventLocation }}
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <div class="time-out-detail mg-t-0">
                          <i class="fas fa-thumbtack"></i>
                          <span class="font-weight-bold"> เจ้าของโครงการ </span>
                          {{ data.ownerName }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </div>
</template>
<script>
import TableDetailProject from "@/components/table/TableDetailProject";

export default {
  components: {
    TableDetailProject,
  },
  mounted() {
    this.dataTable.push(this.data);
  },
  props: ["data"],
  data() {
    return {
      dataTable: [],
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
  methods: {
    returnUrl(url) {
      const myArr = url.split("http://");
      const myArrHttpsmyArr = url.split("https://");
      if (myArr.length > 1) {
        return "http://" + myArr[1];
      } else if (myArrHttpsmyArr.length > 1) {
        return "https://" + myArrHttpsmyArr[1];
      } else {
        return "http://" + url;
      }
    },
  },
};
</script>