<template>
  <DefaultTemplate 
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading">
    <div id="credit-detail" v-if="loading && !loadingData">
      <div class="box-name-page">
        <div>
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="set-breadcrumb"
          >
            <el-breadcrumb-item :to="{ path: '/manage/project' }"
              >จัดการข้อมูลโครงการ</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{
              dataProject.projectName
            }}</el-breadcrumb-item>
          </el-breadcrumb>
          <p class="title mg-t-4">{{ dataProject.projectName }}</p>
        </div>
      </div>
      <!-- <pre>{{dataProject}}</pre> -->
      <el-row :gutter="30">
        <SizeAll :data="dataProject" @morePicture="openDialogPicture" />
      </el-row>
    </div>
    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import SizeAll from "@/components/project/detail/SizeAll";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DefaultTemplate,
    SizeAll
  },
  data() {
    return {
      loading: false,
      idData: this.$route.params.id,
      dataProject: [],
      imgMore: "",
      dialogPicture: false,
      loadingData:true,
    };
  },
  computed: {
    _token() {
      return this.$store.state._token;
    }
  },
  mounted() {
    this.getProject();
  },
  methods: {
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    async getProject() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`project/${this.idData}`)
        .then(res => {
          if (res.data.success) {
            this.dataProject = res.data.obj;
            this.loading = true;
            this.loadingData = false
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch(e => {
            this.loadingData = false

          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (
            e.response.data.status == 404 ||
            e.response.data.status == 422
          ) {
            this.$message({
              message: "ไม่พบข้อมูลโครงการ",
              type: "error",
              duration: 4000
            });
            this.$router.push(`/manage/project`);
          } else {
            this.alertCatchError(e);
          }
          return {
            data: {
              success: false,
              data: e
            }
          };
        });
      return res;
    }
  }
};
</script>
