<template>
  <table
    class="
      el-table
      table-normal
      projectDetail
      animate__animated animate__fadeIn animate__slow
    "
    style="width: 100%"
  >
    <tr v-if="loading">
      <th colspan="2">เงื่อนไขโครงการ</th>
    </tr>
    <tbody class="font-18" v-if="loading">
      <tr v-if="data.businessSizeStatus">
        <td colspan="2">
          <p class="color-main">ขนาดธุรกิจ</p>
          <div v-if="seeMore">
            <p v-if="data.businessSizeS">Size S</p>
            <p v-if="data.businessSizeM">Size M</p>
            <p v-if="data.businessSizeL">Size L</p>
          </div>
        </td>
      </tr>
      <tr v-if="data.isGrade">
        <td colspan="2">
          <p class="color-main">เกรด</p>
          <div v-if="seeMore">
            <div v-for="(grade, indexG) in data.grade" :key="indexG">
              <p>{{ grade.gradeName }}</p>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="data.operatorStatus">
        <td colspan="2">
          <p class="color-main">ประเภทผู้ประกอบการ</p>
          <div v-if="seeMore">
            <div v-if="data.operators.length > 0">
              <div v-for="(operators, indexO) in data.operators" :key="indexO">
                <p>{{ returnOperators(operators) }}</p>
              </div>
            </div>
            <span v-else>-</span>
          </div>
        </td>
      </tr>
      <tr v-if="data.industryStatus">
        <td colspan="2">
          <p class="color-main">ประเภทอุตสาหกรรม</p>
          <div v-if="seeMore">
            <div v-if="data.industries.length > 0">
              <span v-for="(industry, index) in data.industries" :key="index">
                {{ returnIndustryType(industry) }}

                {{ index == data.industries.length - 1 ? "" : ", " }}

                <br v-if="index != data.industries.length - 1" />
              </span>
            </div>

            <span v-else>-</span>
          </div>
        </td>
      </tr>

      <tr v-if="data.isRateReady">
        <td>
          <p class="color-main">ด้านประเมินความพร้อม</p>
          <div v-if="seeMore">
            <div v-for="(readiness, indexG) in data.readiness" :key="indexG">
              <p>{{ returnRate(readiness.value) }}</p>
            </div>
          </div>
        </td>
        <td>
          <div v-if="seeMore">
            <p class="color-main">ระดับ</p>
            <div v-for="(readiness, indexG) in data.readiness" :key="indexG">
              <p>
                ระดับ {{ readiness.levelMin }} - ระดับ {{ readiness.levelMax }}
              </p>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="data.isFactorRateReady">
        <td>
          <p class="color-main">ปัจจัยประเมินความพร้อม</p>
          <div v-if="seeMore">
            <div v-for="(factor, indexG) in data.factor" :key="indexG">
              <p>{{ returnFactor(factor.value) }}</p>
            </div>
          </div>
        </td>
        <td>
          <div v-if="seeMore">
            <p class="color-main">ระดับ</p>
            <div v-for="(factor, indexG) in data.factor" :key="indexG">
              <p>{{ returnScore(factor.score) }}</p>
            </div>
          </div>
        </td>
      </tr>

      <tr v-if="data.isIncome">
        <td colspan="2">
          <p class="color-main">รายได้จากการดำเนินการ (บาท)</p>
          <div v-if="seeMore">
            {{ data.incomeMin | comma }} - {{ data.incomeMax | comma }}
          </div>
        </td>
      </tr>
      <tr v-if="data.shipmentStatus">
        <td colspan="2">
          <p class="color-main">วงเงินค้าขายต่อ Shipment</p>
          <div v-if="seeMore">
            <span v-if="data.shipments.length > 0">
              <span v-for="(shipments, indexS) in data.shipments" :key="indexS">
                <span v-if="shipments == 1">น้อยกว่า 0.5 ล้านบาท</span>
                <span v-else-if="shipments == 2">0.5 - 1 ล้านบาท</span>
                <span v-else-if="shipments == 3">1 ล้านบาทขึ้นไป</span>
                <br />
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </td>
      </tr>
      <tr v-if="data.exportValueStatus">
        <td colspan="2">
          <p class="color-main">มูลค่าการส่งออก</p>
          <div v-if="seeMore">
            <span v-if="data.exportValues.length > 0">
              <span
                v-for="(exportValue, indexE) in data.exportValues"
                :key="indexE"
              >
                <span v-if="exportValue == 1">ไม่เกิน 100 ล้านบาท</span>
                <span v-else-if="exportValue == 2">101-200 ล้านบาท</span>
                <span v-else-if="exportValue == 3">201-300 ล้านบาท</span>
                <span v-else-if="exportValue == 4">301-400 ล้านบาท</span>
                <span v-else-if="exportValue == 5">401-500 ล้านบาท</span>
                <span v-else-if="exportValue == 6">มากกว่า 500 ล้านบาท</span>
                <br />
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </td>
      </tr>
      <tr v-if="data.paymentTermStatus">
        <td colspan="2">
          <p class="color-main">เทอมการชำระเงิน</p>
          <div v-if="seeMore">
            <span v-if="data.paymentTerms.length > 0">
              <span v-for="(term, indexP) in data.paymentTerms" :key="indexP">
                <span v-if="term == 90">ไม่เกิน 90 วัน</span>
                <span v-else-if="term == 120">ไม่เกิน 120 วัน</span>
                <span v-else-if="term == 180">ไม่เกิน 180 วัน</span> <br />
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </td>
      </tr>
      <tr v-if="data.projectTerakStatus">
        <td colspan="2">
          <p class="color-main">โครงการจากระบบ TERAK</p>
          <div v-if="seeMore">
            <span v-if="data.projectTeraks.length > 0">
              <span
                v-for="(terak, indexProject) in data.projectTeraks"
                :key="indexProject"
              >
                <span v-if="returnProjectTERAK(terak) != '-'">
                  {{ indexProject + 1 + ". " }}
                </span>
                {{ returnProjectTERAK(terak) }}
                <br v-if="indexProject != data.projectTeraks.length - 1" />
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="pd-y-7 pd-x-0">
          <p class="text-center mg-0">
            <span
              class="color-main text-decoration-underline cursor-pointer"
              v-if="seeMore == false"
              @click="seeMore = !seeMore"
            >
              เพิ่มเติม <i class="fas fa-chevron-down"></i>
            </span>
            <span
              class="color-main text-decoration-underline cursor-pointer"
              v-else
              @click="seeMore = !seeMore"
            >
              แสดงน้อยลง <i class="fas fa-chevron-up"></i>
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { TERAK } from "@/service/axios";
import { mapState } from "vuex";

import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);

export default {
  props: ["data"],
  data() {
    return {
      loading: false,
      optionsCategory: [],
      optionsQuestion: [],
      countAll: null,
      seeMore: false,
      arrAll: [],
      optionsOperatorType: [
        {
          id: 1,
          label: "ผู้ส่งออก",
        },
        {
          id: 2,
          label: "ผู้มีแผนที่จะส่งออก",
        },
        {
          id: 3,
          label: "ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก",
        },
      ],
    };
  },
  watch: {
    data() {
      this.setData();
    },
  },
  computed: {
    ...mapState({
      optionIndustryType: (state) => state.industryType,
    }),
  },

  mounted() {
    this.getProjectByTerak("CFOE2021");
  },
  methods: {
    setData() {
      this.getByTokenTerak("CFOE2021");
      this.checkSeemore();
    },
    showMore(name) {
      let result = null;
      if (this.seeMore == true) {
        result = true;
      } else {
        if (this.arrAll[0] == name) {
          result = true;
        } else {
          result = false;
        }
      }
      return result;
    },
    checkSeemore() {
      let sumCategory = 0;
      if (this.data.businessSizeStatus == true) {
        this.arrAll.push("businessSizeStatus");
        sumCategory += 1;
      }
      if (this.data.operatorStatus == true) {
        this.arrAll.push("operatorStatus");
        sumCategory += 1;
      }
      if (this.data.industryStatus == true) {
        this.arrAll.push("industryTypeStatus");
        sumCategory += 1;
      }
      if (this.data.readiness.length > 0) {
        this.arrAll.push("readiness");
        sumCategory += 1;
      }
      if (this.data.factor.length > 0) {
        this.arrAll.push("factor");
        sumCategory += 1;
      }
      if (this.data.grade.length > 0) {
        this.arrAll.push("grade");
        sumCategory += 1;
      }
      if (Number(this.data.incomeMax) + Number(this.data.incomeMin) > 0) {
        this.arrAll.push("income");
        sumCategory += 1;
      }
      if (this.data.shipmentStatus == true) {
        this.arrAll.push("shipmentStatus");
      }
      if (this.data.exportValueStatus == true) {
        this.arrAll.push("exportValueStatus");
      }
      if (this.data.paymentTermStatus == true) {
        this.arrAll.push("paymentTermStatus");
      }
      if (this.data.projectTerakStatus == true) {
        this.arrAll.push("projectTERAKStatus");
      }
      this.countAll = sumCategory;
    },
    returnRate(rateId) {
      let findRate = "";
      findRate = this.optionsCategory.find((rate) => rate.id == rateId);
      return findRate.name;
    },
    returnOperators(operators) {
      let findOperators = "";
      findOperators = this.optionsOperatorType.find(
        (operator) => operator.id == operators
      );
      return typeof findOperators != "undefined" ? findOperators.label : "-";
    },
    returnScore(score) {
      let result = "";
      if (score.length > 0) {
        score.forEach((row, index) => {
          result += "ระดับ " + row.score;
          if (index != score.length - 1) {
            result += ", ";
          }
        });
      }
      return result;
    },
    returnFactor(facId) {
      let findFac = "";
      findFac = this.optionsQuestion.find((fac) => fac.id == facId);
      return findFac.name;
    },
    returnIndustryType(industry) {
      let getIndustry = this.optionIndustryType.find((f) => f.id == industry);
      return getIndustry.label;
    },
    getByTokenTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        TERAK.get(`cfoe/cates/questions`)
          .then((res) => {
            if (res.data.success) {
              this.optionsCategory = res.data.cates;
              this.optionsQuestion = res.data.questions;
              this.loading = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    returnProjectTERAK(terak) {
      if (this.optionsRef.length > 0) {
        let getRef = this.optionsRef.find((f) => f.id == terak);
        return typeof getRef != "undefined" ? getRef.projectName : "-";
      }
    },
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        TERAK.get(`cfoe/refs`)
          .then((res) => {
            if (res.data.success) {
              this.optionsRef = res.data.obj;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
  },
};
</script>